// forms/ChatbotForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../styles/ChatbotForm.css';
import qwatImage from '../../assets/QWAT.png'; // Adjust path as needed
import qImage from '../../assets/Q.png'; // Adjust path for the Q image

const ChatbotForm = () => {
    const [formData, setFormData] = useState({ title: 'Chatbots', name: '', email: '', phone: '', message: '' });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs
            .send(
                'service_7zjj3lr',
                'template_r46f6ne',
                formData,
                'Ge5l1rMWAVIvn4WHT'
            )
            .then(() => {
                setLoading(false);
                alert('Your request has been sent!');
            })
            .catch((err) => {
                console.error('Email send error:', err);
                setLoading(false);
                alert('Error sending request.');
            });
    };

    return (
        <div className="form-page">
            <div className="form-container">
                <h2 className="form-title">{formData.title}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" value={formData.name} onChange={handleInputChange} required className="underline-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" value={formData.email} onChange={handleInputChange} required className="underline-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} required className="underline-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" value={formData.message} onChange={handleInputChange} />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Submit'}
                    </button>
                </form>
            </div>
            <div className="q-image-container">
                <div className="q-rotation"> 
                    <img src={qImage} alt="Q" className="q-image" /> {/* Use the CSS class here */}
                </div>
                <img id ="qwat" src={qwatImage} alt="Qwat" />
            </div>
            <br></br>
        </div>
    );
};

export default ChatbotForm;
