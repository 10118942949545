// FooterSectionUnique.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'; // Import from react-scroll
import '../styles/FooterSection.css';
import logo from '../assets/QWAT.png'; // Import your logo image

const FooterSectionUnique = () => {
  const [showAboutPopup, setShowAboutPopup] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const footerRef = useRef(null);

  const toggleAboutPopup = () => setShowAboutPopup(!showAboutPopup);
  const toggleTermsPopup = () => setShowTermsPopup(!showTermsPopup);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Reset visibility after animation duration
          setTimeout(() => setIsVisible(false), 500); // 500ms matches the animation duration
        }
      });
    });

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {/* Collaboration section */}
      <div className="unique-collaboration-container">
        <div className="unique-collab-left">
          <br /><br />
          <h4 className="unique-left-text">Let's Collaborate</h4>
        </div>
        <div className="unique-collab-right">
          <h1 className="unique-main-heading">Ready to turn your ideas into exceptional software?</h1>
          <p className="unique-subtext">
            Contact us today, and let's make something amazing together!
          </p>
          <a
            href="https://chat.whatsapp.com/L9Hw1k6YBdb0VCzu1QvkJZ"
            target="_blank"
            rel="noopener noreferrer"
            className="unique-contact-btn"
          >
            Join With Us
          </a>
        </div>
      </div>

      {/* Footer section */}
      <footer className={`unique-footer-section ${isVisible ? 'animate-span' : ''}`} ref={footerRef}>
        <div className="unique-footer-content">
          <div className="unique-footer-left">
            <h2 className="unique-footer-logo">
              <img src={logo} alt="Qwat Logo" className="footer-logo-image" /> {/* Replace text with image */}
            </h2>
            <p>
              QWAT is where your imagination and our expertise converge to create
              software solutions that elevate your brand and set you apart from the competition.
            </p>
            <p className="unique-contact-info">
              <a href="mailto:info@qwat.in" target="_blank" rel="noopener noreferrer">
                info@qwat.in
              </a>
              <br />
              <br />
              <a href="tel:+919943999489" target="_blank" rel="noopener noreferrer">
                +919943999489
              </a>
            </p>
          </div>

          <div className="unique-footer-right">
            <div className="unique-company-links">
              <h4>Company</h4>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li>
                  <ScrollLink 
                      to="services-accordion-section" 
                      smooth={true} 
                      duration={800}
                      offset={-50}
                      className="service-link"
                  >
                    Services
                  </ScrollLink>
                </li>
              </ul>
            </div>

            <div className="unique-contact-links">
              <h4>About us</h4>
              <ul>
                <li><Link to="/terms">Terms and Conditions</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="unique-social-icons">
          <a href="tel:+919943999489" target="_blank" rel="noopener noreferrer" aria-label="Phone">
            <i className="fas fa-phone"></i>
          </a>
          <a href="https://wa.me/919080870732" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="mailto:info@qwat.in" target="_blank" rel="noopener noreferrer" aria-label="Email">
            <i className="fas fa-envelope"></i>
          </a>
        </div>

        <div className="unique-footer-bottom">
          <p>Copyright © 2024 QWAT®. All rights reserved.</p>
        </div>
      </footer>

      {/* About Popup Modal */}
      {showAboutPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>About QWAT</h2>
            <br />
            <p>
              QWAT is your trusted partner in delivering top-notch software solutions that enhance business efficiency and drive growth.
            </p>
            <button className="popup-close" onClick={toggleAboutPopup}>&#10005;</button>
          </div>
        </div>
      )}

      {/* Terms & Conditions Popup Modal */}
      {showTermsPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Terms & Conditions</h2>
            <br />
            <p>
              By using our services, you agree to the following terms and conditions.
            </p>
            <button className="popup-close" onClick={toggleTermsPopup}>&#10005;</button>
          </div>
        </div>
      )}
    </>
  );
};

export default FooterSectionUnique;
