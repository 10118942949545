import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SupportPage.css';

// Import images directly
import aiMlImage from '../assets/1.jpg';
import chatbotsImage from '../assets/2.jpg';
import webDevImage from '../assets/3.jpg';
import mobileDevImage from '../assets/4.jpg';
import ecommerceImage from '../assets/5.jpg';
import iotImage from '../assets/6.jpg';

const supportItems = [
    { path: "/ai-ml", image: aiMlImage },
    { path: "/chatbots", image: chatbotsImage },
    { path: "/web-dev", image: webDevImage },
    { path: "/mobile-dev", image: mobileDevImage },
    { path: "/ecommerce", image: ecommerceImage },
    { path: "/iot", image: iotImage },
];

const SupportPage = () => {
    const sectionRef = useRef(null);
    const navigate = useNavigate();

    const handleCarouselClick = (path) => {
        navigate(path);
    };

    useEffect(() => {
        // Animation-related effects can be handled here if needed.
    }, []);

    return (
        <div ref={sectionRef} className="support-container">
            <h2>Our Support Offerings</h2>
            <div className="carousel">
                <div className="carousel-control-button left">
                    <input type="radio" name="carousel-control-input" />
                </div>
                <div className="carousel-control-button right">
                    <input type="radio" name="carousel-control-input" checked />
                </div>
                <div className="carousel-rotation-direction">
                    <ul className="carousel-item-wrapper" style={{ '--_num-elements': supportItems.length }}>
                        {supportItems.map((item, index) => (
                            <li
                                key={index}
                                className="carousel-item"
                                style={{
                                    '--_index': index + 1,
                                }}
                                onClick={() => handleCarouselClick(item.path)}
                            >
                                <img src={item.image} alt={item.title} className="carousel-image" />
                                <p>{item.title}</p>
                            </li>
                        ))}
                        <li className="carousel-ground"></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
