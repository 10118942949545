import React from 'react';
import '../styles/Header.css';  // Assuming you're using the correct path
import logo from '../assets/QWAT.png';  // Update the path if necessary

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <a href="https://qwat.in" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Qwat Logo" />
        </a>
      </div>
      <nav>
        <ul className="nav-links">
          <li><a href="https://blog.qwat.in" target="_blank" rel="noopener noreferrer">Our Blogs</a></li>
        </ul>
      </nav>
      <a 
        href="https://wa.me/919080870732" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="contact-btn"
      >
        LET'S TALK
      </a>
    </header>
  );
};

export default Header;
