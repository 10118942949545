import React from 'react';

const Terms = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1><strong>Terms and Conditions</strong></h1>
      <br></br>

      <p>Welcome to Qwat!</p>

      <p>These terms and conditions outline the rules and regulations for the use of Qwat's Website, located at https://qwat.in/.</p>

      <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Qwat if you do not agree to take all of the terms and conditions stated on this page.</p>

      <br></br><h2><strong>1. Terminology</strong></h2>
      <p>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refer to you, the person logging on to this website and compliant with the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us" refer to our Company. "Party", "Parties", or "Us" refer to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake our assistance to the Client in the most appropriate manner, in accordance with prevailing law.</p>

      <br></br><h2><strong>2. Cookies</strong></h2>
      <p>We employ the use of cookies. By accessing Qwat, you agreed to use cookies in agreement with Qwat's Privacy Policy. Cookies are used to enhance user experience and some of our affiliate/advertising partners may also use cookies.</p>

      <br></br><h2><strong>3. License</strong></h2>
      <p>Unless otherwise stated, Qwat and/or its licensors own the intellectual property rights for all material on Qwat. All rights are reserved. You may access this for your personal use, subject to restrictions in these terms and conditions.</p>
      <p>You must not:</p>
      <ul>
        <li>Republish material from Qwat</li>
        <li>Sell, rent, or sub-license material from Qwat</li>
        <li>Reproduce, duplicate, or copy material from Qwat</li>
        <li>Redistribute content from Qwat</li>
      </ul>

      <br></br><h2><strong>4. Comments</strong></h2>
      <p>Qwat does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views of Qwat. Qwat is not liable for Comments or any liability, damages, or expenses caused and/or suffered as a result of Comments posted on this website.</p>
      <p>Qwat reserves the right to monitor and remove Comments deemed inappropriate or causing a breach of these Terms and Conditions.</p>

      <br></br><h2><strong>5. Hyperlinking to our Content</strong></h2>
      <p>Approved organizations may hyperlink to our Website under certain conditions. We may approve link requests from organizations if they meet specific criteria, including a benefit to us from the visibility of the hyperlink.</p>

      <br></br><h2><strong>6. iFrames</strong></h2>
      <p>Without prior approval, you may not create frames around our Webpages that alter the visual presentation or appearance of our Website.</p>

      <br></br><h2><strong>7. Content Liability</strong></h2>
      <p>We shall not be held responsible for any content that appears on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, criminal, or infringing upon any third-party rights.</p>

      <br></br><h2><strong>8. Privacy</strong></h2>
      <p>Please read our Privacy Policy for details.</p>

      <br></br><h2><strong>9. Reservation of Rights</strong></h2>
      <p>We reserve the right to request that you remove any links or any particular link to our Website and reserve the right to amend these terms and conditions.</p>

      <br></br><h2><strong>10. Removal of Links</strong></h2>
      <p>If you find any link on our Website that is offensive for any reason, you may contact us. We will consider requests to remove links, but we are not obligated to respond or take action.</p>

      <br></br><h2><strong>11. Disclaimer</strong></h2>
      <p>To the maximum extent permitted by law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will limit any liabilities in a way not permitted under applicable law.</p>

      <p>As long as the website and the information on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
    </div>
  );
};

export default Terms;
