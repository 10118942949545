import React from 'react';
import { motion } from 'framer-motion';
import '../styles/HomePage.css';
import qwatLogo from '../assets/QWAT.png'; // Update the path to your image file

const HomePage = () => {
  // Reduced number of triangle ray elements
  const rays = Array.from({ length: 10 }).map((_, index) => (
    <div
      key={index}
      className="ray"
      style={{
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        transform: `rotate(${Math.random() * 360}deg)`,
        animationDelay: `${Math.random() * 2}s`,
      }}
    />
  ));

  return (
    <div className="homepage">
      {/* Title Image */}
      <motion.img 
        src={qwatLogo} 
        alt="QWAT Logo" 
        className="title" 
        style={{ maxWidth: '100%', height: 'auto' }} // Adjust styling as needed
      />

      {/* Event Information */}
      <motion.div className="event-info">
        <p>Services available for <br></br>24/7</p>
      </motion.div>

      {/* Footer Details */}
      <motion.p className="details">
        Whether you're a startup looking to <br />
        establish your brand identity or an established business aiming to <br />
        refresh your image, we've got the creative <br />
        firepower to make it happen.
      </motion.p>

      {/* Triangle Rays */}
      <div className="triangle-rays">{rays}</div>
    </div>
  );
};

export default HomePage;
