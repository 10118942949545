import React, { useState, useEffect, useRef } from 'react';
import '../styles/ServicesAccordion.css'; // Importing styles for the accordion

const ServicesAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const accordionRef = useRef(null); // Reference to the accordion container

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const services = [
    {
      title: "Visual Branding",
      content: "Create a powerful first impression with our tailored visual branding solutions. From logo design to comprehensive brand identity development, we ensure your brand stands out. Our expert designers translate your vision into impactful visuals that resonate with your audience, establishing your brand's unique presence across all touchpoints. Let us bring your story to life with creative and cohesive branding strategies."
    },
    {
      title: "Creative Campaign",
      content: "Drive engagement and elevate your brand with our innovative and data-driven creative campaigns. Whether it’s digital marketing, social media content, or product launches, we craft strategies that capture attention and deliver results. Our cross-platform campaigns are designed to amplify your message and connect with your target audience on a deeper level."
    },
    {
      title: "UI/UX Design",
      content: "Enhance user experience with intuitive and user-centered design. We specialize in crafting seamless, engaging interfaces that prioritize both aesthetics and functionality. From mobile apps to websites, we ensure a smooth journey for your users, maximizing their satisfaction and interaction with your product. Our UI/UX development process integrates research, design, and technology to offer a top-tier digital experience."
    },
    {
      title: "Development",
      content: "Empower your business with our comprehensive development solutions designed to drive growth, efficiency, and innovation. We offer end-to-end development services, delivering cutting-edge websites, mobile applications, IoT systems, AI/ML solutions, and e-commerce platforms that cater to your specific needs. Our expert team ensures seamless integration of technology, exceptional performance, and user-centric design across all projects."
    }
  ];

  // Use Intersection Observer to trigger animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          } else {
            entry.target.classList.remove('animate');
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = accordionRef.current.querySelectorAll('.accordion-item');
    items.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="accordion-container" ref={accordionRef}>
      <div className="services-title">
        <h2>Our Services</h2>
      </div>
      <div className="accordion">
        {services.map((service, index) => (
          <div key={index} className="accordion-item">
            <div className="accordion-title" onClick={() => toggleAccordion(index)}>
              <h3>{service.title}</h3>
              <span>{activeIndex === index ? '▴' : '▾'}</span>
            </div>
            {activeIndex === index && (
              <div className="accordion-content">
                <p>{service.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesAccordion;
