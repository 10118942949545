import React, { useEffect, useState, useRef } from 'react';
import '../styles/DevelopmentApproach.css';
import sharedUnderstandingIcon from '../assets/dev.png';
import experienceIcon from '../assets/prove.png';
import securityIcon from '../assets/security.png';
import codeReviewIcon from '../assets/review.png';
import qaIcon from '../assets/secure.png';
import bigQImage from '../assets/Q.png'; // Import your rotating Q image
import UI from '../assets/UI.png';
import QwatImage from '../assets/Qwat-span.png'; // Import the Qwat image to display below

const DevelopmentApproach = () => {
  const approachItems = [
    {
      title: "UX Driven Engineering",
      content: "Unlike other companies, we are a UX-first development company. Projects are driven by designers who ensure design and experiences translate to code.",
      icon: <img src={UI} alt="Driven Engineering" />
    },
    {
      title: "Developing Shared Understanding",
      content: "We ensure clear communication and shared goals with clients to make sure design and experiences translate seamlessly into the development process.",
      icon: <img src={sharedUnderstandingIcon} alt="Shared Understanding" />
    },
    {
      title: "Proven Experience and Expertise",
      content: "Our team brings years of expertise in designing and developing solutions that solve real-world problems, with a focus on innovation.",
      icon: <img src={experienceIcon} alt="Experience and Expertise" />
    },
    {
      title: "Security & Intellectual Property (IP)",
      content: "We take security seriously. Our development practices include rigorous testing to ensure data protection and intellectual property security.",
      icon: <img src={securityIcon} alt="Security" />
    },
    {
      title: "Code Reviews",
      content: "All code goes through a stringent review process to maintain high standards of quality, ensuring bug-free and maintainable code.",
      icon: <img src={codeReviewIcon} alt="Code Review" />
    },
    {
      title: "Quality Assurance & Testing",
      content: "We follow best practices in quality assurance, ensuring that every product we deliver is thoroughly tested and meets your expectations.",
      icon: <img src={qaIcon} alt="Quality Assurance" />
    }
  ];

  const [isImageVisible, setIsImageVisible] = useState(false);
  const sectionRef = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsImageVisible(true); // Show the image when section is in view
      } else {
        setIsImageVisible(false); // Hide image when out of view
      }
    };

    const handleGridIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.5,
    });

    const gridObserver = new IntersectionObserver(handleGridIntersection, {
      root: null,
      threshold: 0.1,
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    const gridItems = gridRef.current.querySelectorAll('.approach-card');
    gridItems.forEach((item) => gridObserver.observe(item));

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      gridItems.forEach((item) => gridObserver.unobserve(item));
    };
  }, []);

  return (
    <div ref={sectionRef} className="development-approach-container">
      <div className='click-me'><h2>Click the cards to submit enquiry</h2><br /><br /></div>
      <div className="rainbow-line"></div>
      <div  class='our'>
      <p>Our Design and</p>
      </div>
      <br></br>
      <h2 className="section-title">Development Approach</h2>

      <div ref={gridRef} className="approach-grid">
        {approachItems.map((item, index) => (
          <div key={index} className="approach-card">
            <div className="icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
          </div>
        ))}
      </div>

      {/* Qwat Animation (rotating Q) */}
      <div className="qwat-animation">
        <div className="big-q red">
          <img src={bigQImage} alt="Rotating Q" className="big-q-image" />
        </div>

        {/* Display Qwat image below the rotating Q when section is in view */}
        {isImageVisible && (
          <div className="qwat-image-container">
            <img src={QwatImage} alt="Qwat Logo" className="qwat-image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DevelopmentApproach;
