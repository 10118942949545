// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HomePage';
import ServicesAccordion from './components/ServicesAccordion';
import DevelopmentApproach from './components/DevelopmentApproach';
import FooterSectionUnique from './components/Footersection';
import '@fortawesome/fontawesome-free/css/all.min.css';
import SupportPage from './components/SupportPage';
import AIMLForm from './components/forms/AIMLForm'; // Import your form components
import ChatbotForm from './components/forms/ChatbotForm';
import WebDevForm from './components/forms/WebAppForm';
import MobileDevForm from './components/forms/MobileAppForm';
import EcommerceForm from './components/forms/ECommerceForm';
import IoTForm from './components/forms/IoTForm';
import TermsConditions from './components/terms';
import PrivacyPolicy from './components/privacy-policy';
import { Helmet } from 'react-helmet';

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-6C31HG16YN');
  }, []);

  return (
    <div className="App">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6C31HG16YN"></script>
      </Helmet>
      
      <Router>
        <Header /> {/* Header will always be shown */}

        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <div id="services-accordion-section">
                <ServicesAccordion />
              </div>
              <SupportPage />
              <DevelopmentApproach />
            </>
          } />
          <Route path="/ai-ml" element={<AIMLForm />} />
          <Route path="/chatbots" element={<ChatbotForm />} />
          <Route path="/web-dev" element={<WebDevForm />} />
          <Route path="/mobile-dev" element={<MobileDevForm />} />
          <Route path="/ecommerce" element={<EcommerceForm />} />
          <Route path="/iot" element={<IoTForm />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        
        <FooterSectionUnique />
      </Router>
    </div>
  );
}

export default App;
